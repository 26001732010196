import React from 'react';
import GoogleMapReact from 'google-map-react';
import { MapStyles } from './MapStyles';

const ZoneMap = ({ zoneCoordinates, propertyLocation }) => {
  const defaultCenter = {
    lat: 48.884132385253906,
    lng: 2.3254213333129883,
  };
  const defaultZoom = 11;

  const handleGoogleApiLoaded = (map, maps, zoneCoordinates) => {
    if (maps && zoneCoordinates?.length > 0) {
      let points = zoneCoordinates.map(
        (coordinate) => new maps.LatLng(coordinate.lat, coordinate.lng),
      );

      let mainZonePolygon = new maps.Polygon({
        paths: points,
        strokeColor: '#8782a6',
        fillColor: '#8782a6',
        fillOpacity: 0.2,
        strokeOpacity: 1.0,
        strokeWeight: 1,
      });

      // Hide controls
      map.setOptions({ disableDefaultUI: true, styles: MapStyles });

      mainZonePolygon.setMap(map);
      let bounds = new maps.LatLngBounds();

      mainZonePolygon.getPath().forEach(function (path, index) {
        bounds.extend(path);
      });

      // Fit Polygon with padding
      map.fitBounds(bounds, 1);
    }
  };

  return (
    <GoogleMapReact
      defaultCenter={defaultCenter}
      defaultZoom={defaultZoom}
      bootstrapURLKeys={{
        key: process.env.GATSBY_GOOGLEMAPS_API_KEY,
        language: 'fr',
      }}
      onGoogleApiLoaded={({ map, maps }) => {
        handleGoogleApiLoaded(map, maps, zoneCoordinates);
      }}
    />
  );
};

export default ZoneMap;
