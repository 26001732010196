export const sliceIntoNArrays = (values, nbResults) => {
  const result = [];
  const nbItemsByChunk =
    values.length <= nbResults
      ? values.length
      : Math.ceil(values.length / nbResults);

  for (let i = 0; i < nbResults; i++) {
    let start = i * nbItemsByChunk;

    // No more values - return empty array
    if (start + 1 > values.length) {
      result[i] = [];
      continue;
    }

    let end = start + nbItemsByChunk;

    //console.log("i=" + i + ", start=" + start + ", end=" + end);

    if (end + 1 > values.length) {
      end = values.length;
    }

    //console.log("> i=" + i + ", start=" + start + ", end=" + end);
    //console.log(values.slice(start, end));

    result[i] = values.length > start ? values.slice(start, end) : [];
  }

  //console.warn(result);
  //console.error(result);
  return result;
};

export const slashPrefix = (str) => {
  if (str.startsWith('/')) {
    return str;
  }

  return '/' + str;
};

export const truncateString = (value, length) => {
  return value !== null && value.length > length
    ? value.substring(0, length) + '...'
    : value;
};

// On renvoie un entier aléatoire entre une valeur min (incluse)
// et une valeur max (exclue).
// Attention : si on utilisait Math.round(), on aurait une distribution
// non uniforme !
export const randomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
};
